import { FieldsWrapper } from '@app/components/atm.wrapper/wrapper.component'
import { DebouncedSearchExams } from '@app/components/legacy/mol.search/debounced-search-exams.component'
import { SearchQueryParam } from '@app/components/legacy/mol.search/debounced-search.utils'
import { FetchContainer } from '@app/components/legacy/obj.http-container/fetch-container.component'
import { getPaginationQueryParam } from '@app/components/legacy/obj.pagination-container/pagination-query-param-getter'
import {
  //TextListFooter,
  TextListHeader,
  TextListItem,
  TextListPage,
  TextListPageData
} from '@app/components/org.text-list-page/text-list-page.component'
import { ClientDoctorSelectContext } from '@app/context/client-doctor-select'
import {
  ExamSearchResponse,
  mapToExamSearchResponse
} from '@app/data/http/dto/exam-elastic-search.response'
import flashDispatcherService from '@root/src/components/legacy/obj.flash-wrapper/flash-dispatcher.service'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import {
  CosmicjsInformacoesEstaticas,
  CosmicjsInformacoesEstaticasMetadataSeo
} from '@root/src/data/graphql/graphql-types'
import { buildExamElasticSearchRequest } from '@root/src/data/http/request-builder/exam-elastic-search.request'
import { Col, Row } from 'atomic'
import { navigate } from 'gatsby'
import * as queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { hasWindow } from 'utils/browser'
import { appPaths, getCanonicalUrl } from 'utils/path'
import { getClientAndDoctorUrl } from 'utils/url'
import sanitizeRegex from '@root/src/utils/sanitize-regex-elastic'
import axios from '@root/src/utils/base-axios.js'

export interface ExamListPageProps {
  items: TextListItem[]
  staticData: CosmicjsInformacoesEstaticas
  siteUrl: string
  fleuryElasticSearchUrl: string
  location: Location
}

export const ExamListPage: React.FunctionComponent<ExamListPageProps> = props => {
  const pageData: Partial<TextListPageData> = getPartialPageData(
    props.staticData,
    props.siteUrl,
    props.staticData.metadata.seo
  )
  const featuredExams = props.items
  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)
  const currentQueryParam: Partial<SearchQueryParam> = queryString.parse(props.location.search)
  const busca = currentQueryParam.busca || ''
  //const [searchModeActive, setSearchModeActive] = useState(busca.length > 0)
  const searchModeActive = false

  const [examsClassification, setExamsClassification] = useState([
		{
			classification: 'Exames de sangue, urina e mais',
			selected: false,
			initials: 'AC',
			quantity: 0
		},
		{
			classification: 'Exames de imagem',
			selected: false,
			initials: 'CD',
			quantity: 0
		},
		{
			classification: 'Vacinas e outros procedimentos',
			selected: false,
			initials: 'CJ',
			quantity: 0
		}
	])
  const [changedFilter, setChangedFilter] = useState(false)
	const [queryFilter, setQueryFilter] = useState([])
  const [responseElastic, setResponseElastic] = useState<ExamSearchResponse>()
  const [countExamsResults, setCountExamsResults] = useState<any>()
  
  // const handleSeeAll = React.useCallback(() => {
  //   setSearchModeActive(true)
  // }, [])
  // const handleValueChange = React.useCallback(
  //   value => {
  //     const nextQueryParam: Partial<SearchQueryParam> = {
  //       ...currentQueryParam,
  //       busca: value,
  //       page: 1
  //     }
  //     const pathname = hasWindow() ? window.location.pathname : ''
  //     navigate(`${pathname}/?${queryString.stringify(nextQueryParam)}`)
  //   },
  //   [currentQueryParam.busca, currentQueryParam.page, currentQueryParam.limit, countClassification, queryFilter]
  // )

  // const handleSeeAll = () => {
  //   setSearchModeActive(true)
  // }

  const handleValueChange = value => {
    const nextQueryParam: Partial<SearchQueryParam> = {
      ...currentQueryParam,
      busca: value,
      page: 1
    }
    const pathname = hasWindow() ? window.location.pathname : ''
    navigate(`${pathname}/?${queryString.stringify(nextQueryParam)}`)
  }

  const handleClickFilter = position => {
		setExamsClassification(prevArray =>
			prevArray.map((item, index) => (index === position ? { ...item, selected: !item.selected } : item))
		)
    setQueryFilter([])
		setChangedFilter(true)
	}
  
  useEffect(() => {
		if (changedFilter) {
			// eslint-disable-next-line array-callback-return
			examsClassification.map(item => {
				if (item.selected) {
					queryFilter.push({
						match: {
							'classification.initials': item.initials
						}
					})
				}
			})      
      //setSearchModeActive(true)      
      handleValueChange(busca)
      fetchData()
		}
    setChangedFilter(false)    
	}, [changedFilter])

  useEffect(() => {
    if (countExamsResults) {
      setExamsClassification(prevArray =>
        prevArray.map(item => {
          if (item.initials === 'AC') {
            if (item.selected && countExamsResults?.classification_count_ac?.doc_count == 0) {
              handleClickFilter(0)
            }
            return {
              ...item,
              quantity: countExamsResults?.classification_count_ac?.doc_count
            }
          } else if (item.initials === 'CD') {
            if (item.selected && countExamsResults?.classification_count_cd?.doc_count == 0) {
              handleClickFilter(1)
            }
            return {
              ...item,
              quantity: countExamsResults?.classification_count_cd?.doc_count
            }
          } else if (item.initials === 'CJ') {
            if (item.selected && countExamsResults?.classification_count_cj?.doc_count == 0) {
              handleClickFilter(2)
            }
            return {
              ...item,
              quantity: countExamsResults?.classification_count_cj?.doc_count
            }
          } else {
            return item
          }
        })
      )
    }
  }, [countExamsResults])
    
  const params = getPaginationQueryParam(currentQueryParam)
  
  const onError = React.useCallback(() => {
    flashDispatcherService.dispatchMessage('Ocorreu um erro em sua busca.', 'alert')  
  }, [])

  // *****************************
  //  filtro abaixo pra o exame id: 9057 
  //  deverá ser retirado após correção das informações 
  //  pelo setor de cadastro
  // *****************************

  const fetchData = async () => {
		const queryMatch = {
			query: {
				bool: {
					must: [
						{
							query_string: {
								query: `*${busca.replace(sanitizeRegex, '')}*`,
								fields: ['name', 'synonyms', 'slug', 'productInitials']
							}
						},
						{
							bool: {
								should: queryFilter
							}
						}
					],
          must_not: [
            {
              term: {
                id: 9056
              }
            },
            {
              term: {
                id: 9057
              }
            },
            {
              term: {
                id: 9058
              }
            }
          ]
				}
			}
		}

		try {
			const data = await axios.post('/elasticsearch/digital/free-search', {
				search: {
					...queryMatch,
					from: (params.page - 1) * params.limit,
					size: params.limit
				},
				index: `${process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase()}-exames`
			})

      const response = mapToExamSearchResponse(data)
      setResponseElastic(response)      

			const unfilteredQuery = {
				query: {
					bool: {
						must: {
							query_string: {
								query: `*${busca.replace(sanitizeRegex, '')}*`,
								fields: ['name', 'synonyms', 'slug', 'productInitials']
							}
						}
					}
				}
			}

			const countClassification = await axios.post('/elasticsearch/digital/free-search', {
				search: {
					...unfilteredQuery,
					size: 0,
					aggs: {
						classification_count_ac: {
							filter: {
								term: {
									'classification.initials.keyword': 'AC'
								}
							},
							aggs: {
								count: {
									value_count: {
										field: 'product.initials.keyword'
									}
								}
							}
						},
						classification_count_cd: {
							filter: {
								term: {
									'classification.initials.keyword': 'CD'
								}
							},
							aggs: {
								count: {
									value_count: {
										field: 'product.initials.keyword'
									}
								}
							}
						},
						classification_count_cj: {
							filter: {
								term: {
									'classification.initials.keyword': 'CJ'
								}
							},
							aggs: {
								count: {
									value_count: {
										field: 'product.initials.keyword'
									}
								}
							}
						}
					}
				},
				index: `${process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase()}-exames`
			})

      setCountExamsResults(countClassification.data.aggregations)

			setExamsClassification(prevArray =>
				prevArray.map(item => {
					if (item.initials === 'AC') {
						return { ...item, quantity: countClassification.data.aggregations.classification_count_ac.doc_count }
					} else if (item.initials === 'CD') {
						return { ...item, quantity: countClassification.data.aggregations.classification_count_cd.doc_count }
					} else if (item.initials === 'CJ') {
						return { ...item, quantity: countClassification.data.aggregations.classification_count_cj.doc_count }
					} else {
						return item
					}
				})
			)		
		} catch {
			console.error('Error na requisicao do elastic para exames')
		}
	}

  useEffect(() => {        
    fetchData() 
  }, [params.page, params.limit])

  useEffect(() => {
    fetchData() 
  }, [busca])

  useEffect(() => {    
    handleValueChange('')
  }, [])  

  return (
    <ClientDoctorSelectContext.Provider value={{ clientUrl: clientPath, doctorUrl: doctorPath }}>
      <IndexLayout location={props.location}>
        <FetchContainer
          input={
            searchModeActive
              ? buildExamElasticSearchRequest(busca, params.page, params.limit, queryFilter)
              : null
          }
          mapper={mapToExamSearchResponse}
          defaultBaseUrl={props.fleuryElasticSearchUrl}
          onError={onError}
        >
          {state => {            

            if (responseElastic) {
              pageData.getItems = () => responseElastic.exams
              pageData.itemsCount = responseElastic.total.value
            }             
            else {
              pageData.getItems =  () => [{title: '', slug: '', synonyms:[]}]
              pageData.itemsCount = 0
            }
            pageData.showItemCount = pageData.itemsCount > 0
            
            return (
              <TextListPage 
                loading={state.loading}
                pageData={pageData as TextListPageData}
                location={props.location}
                featuredExams={featuredExams}
                examsClassification={examsClassification}
                setExamsClassification={setExamsClassification}
                handleClickFilter={handleClickFilter}
              >
                <TextListHeader>
                  <Row mb>
                    <Col xs={12} md={12}>
                      <FieldsWrapper>
                        <DebouncedSearchExams
                          placeholder={'Buscar exame ou vacina'}
                          loading={state.loading}
                          onValueChange={handleValueChange}
                          onSubmit={handleValueChange}
                          id="input-exam-search"
                        />
                      </FieldsWrapper>
                    </Col>
                  </Row>                  
                </TextListHeader>
              </TextListPage>
            )
          }}
        </FetchContainer>
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

const getPartialPageData = (
  staticData: CosmicjsInformacoesEstaticas,
  siteUrl: string,
  seoData: CosmicjsInformacoesEstaticasMetadataSeo
): Partial<TextListPageData> => {

  return {
    title: staticData.metadata.titulo,
    searchPlaceholder: staticData.metadata.buscaPlaceholder,
    col2Title: staticData.metadata.col2Titulo,
    col2ContentHtml: staticData.metadata.col2Conteudo,
    selectPlaceholder: 'Mais buscados',
    seo: {
      siteUrl,
      canonicalUrl: getCanonicalUrl(siteUrl, appPaths.exams.path),
      title: seoData.titulo,
      description: seoData.descricao,
      imageUrl: seoData.imagem.url,
      imageAlt: seoData.imagemAlternativa
    }
  }
}
