import React, { useEffect } from 'react'
import { Form } from 'atomic/legacy/obj.form'
import { RelativeWrapper } from 'atomic/obj.wrappers'
import { hasWindow } from 'utils/browser'
import { debounceWithLimit } from 'utils/debounce/debounce'
import SvgIcSearch from 'atomic/atm.svg-icon/ic-search'
import * as queryString from 'query-string'
import { SearchWrapper, TextField } from './debounced-search-exams.component.style'
import { SearchQueryParam } from './debounced-search.utils'
import { theme } from '../../obj.theme'

export interface DebouncedSearchExamsProps {
  label?: string
  placeholder?: string
  id?: string
  loading?: boolean
  onSubmit: (value: string) => void
  onValueChange: (value: string) => void
}

export const DebouncedSearchExams: React.FunctionComponent<DebouncedSearchExamsProps> = props => {
  const debouncedHandleChange = React.useRef<any>()
  useEffect(() => {
    debouncedHandleChange.current = debounceWithLimit(props.onValueChange, 3)
  }, [props.onValueChange])

  const handleValueChange = React.useCallback(value => {
    debouncedHandleChange.current(value)
  }, [])

  const search: Partial<SearchQueryParam> = queryString.parse(
    hasWindow() ? window.location.search : ''
  )
  return (
    <RelativeWrapper>
      <Form id="form-debounced-search" onSubmit={() => {}}>
        <SearchWrapper>
          <Form.Field
            initialValue={search.busca}
            name="search"          
          >
            <TextField
              placeholder={props.placeholder}
              id={props.id}
              onChange={e => handleValueChange(e.target.value)}              
            />
          </Form.Field>
          <SvgIcSearch height={40} fill={theme.color.primary}/>          
        </SearchWrapper>
      </Form>
    </RelativeWrapper>
  )
}
